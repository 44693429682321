<template>
  <div v-if="type != 1" class="ecvNavMenuBox" :class="{'active': isOpen}">
    <!-- 僅有兩層 -->
    <div
      v-if="type === 2"
      class="ecvNavMenu d-flex flex-wrap justify-content-around"
    >
      <div
        v-for="(secItem, index) in computedSecList"
        :key="index"
        class="d-block"
      >
        <div class="ecvNavMenuTitle">
          <link-node tooltip :node="secItem" :reDetectKey="linkNodeKey"></link-node>
        </div>
      </div>
    </div>
    <!-- 有三層 -->
    <div
      v-if="type === 3"
      class="
        ecvNavMenu ecvNavMenu--has-third
        d-flex
        flex-wrap
        justify-content-around
      "
    >
      <div
        v-for="(secItem, index) in computedSecList"
        :key="index"
        class="ecvSecMenu d-block"
      >
        <div class="ecvNavMenuTitle" :class="{ 'mr-0': secItem.linkNext }">
          <link-node
            tooltip
            class="text-overflow-ep-2"
            :node="secItem"
            :reDetectKey="linkNodeKey"
          ></link-node>
        </div>
        <div class="ecvNavMenuItemWrapper">
          <ul class="ecvNavMenuItemBox">
            <li
              v-for="(thirdItem, index) in secItem.children"
              :key="index"
              class="ecvNavMenuItem"
            >
              <link-node
                tooltip
                :node="thirdItem"
                :reDetectKey="linkNodeKey"
              ></link-node>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    secList: {
      type: Array,
    },
    type: {
      type: Number,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    linkNodeKey: 0,
  }),
  computed: {
    computedSecList() {
      if (this.type === 2) return this.secList;

      let array = [];
      this.secList.forEach((secItem) => {
        if (secItem.children.length == 0) {
          array.push(secItem);
          return;
        }
        let chunks = _chunk(secItem.children, 5);
        chunks.forEach((chunk, index) => {
          let newObj = {
            ...secItem,
            name: index == 0 ? secItem.name : "",
            children: chunk,
            linkNext: chunks.length > 1 && index != chunks.length - 1,
          };
          array.push(newObj);
        });
      });
      return array;
    },
    windowWidth() {
      return this.$store.getters["base/windowWidth"];
    },
  },
  watch: {
    async windowWidth() {
      await this.modifyMenuHeight();
    },
    isOpen() {
      if(this.isOpen) this.linkNodeKey += 1
    },
  },
  async mounted() {
    await this.modifyMenuHeight();
  },
  methods: {
    async modifyMenuHeight() {
      if (this.type != 3) return;
      await this.$nextTick();
      const target = $(this.$el).find(".ecvNavMenu--has-third");
      if(target.length == 0) return

      target.css('height', '') // 避免rwd時產生問題, ex: 一開始mobile size不會生成選單因此高度為0, 此時切到pc就會長不出來選單
      let target_height = target.height();

      const secMenu = target.find(".ecvSecMenu");
      if (!secMenu.length == 0) return;
      const margin = secMenu.outerHeight(true) - secMenu.outerHeight();
      target.height(target_height - margin);
    },
  },
};
</script>
